<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考务管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">考场安排</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="考务名称" class="searchboxItem ci-full">
              <span class="itemLabel">考务名称:</span>
              <el-input v-model="searchForm.activityName" clearable size="small" placeholder="请输入考务名称"></el-input>
            </div>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="getData()">查询</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="100px" />
              <el-table-column label="考务名称" align="center" prop="activityName" show-overflow-tooltip min-width="160px" />
              <el-table-column label="培训工种" align="center" prop="occupationName" show-overflow-tooltip
                min-width="160px" />
              <el-table-column label="培训等级" align="center" prop="levelCode" show-overflow-tooltip
                min-width="120px"><template slot-scope="scope" class="flexcc">
                  {{ $setDictionary("ET_TRAIN_LEVEL", scope.row.levelCode) }}
                </template>
              </el-table-column>
              <el-table-column label="考试时间" align="center" show-overflow-tooltip min-width="260px"><template
                  slot-scope="{ row }">
                  <template v-if="row.examList.length > 0">
                    <div v-for="item in row.examList" :key="item.activityExamId">
                      {{
                        $setDictionary("EA_EXAM_SUBJECT_CODE", item.subjectCode)
                      }}
                      : {{ item.startTime }} - {{ item.endTime }}
                    </div>
                  </template>
                  <template v-else> 无 </template>
                </template>
              </el-table-column>
              <el-table-column label="考务状态" align="center" prop="state" show-overflow-tooltip min-width="160px">
                <template slot-scope="scope" class="flexcc">
                  {{ $setDictionary("EA_ACTIVITY_STATE", scope.row.state) }}
                </template>
              </el-table-column>
              <el-table-column label="考试人数" align="center" prop="examUserNum" show-overflow-tooltip min-width="120px" />
              <el-table-column label="操作" align="center" width="260px" fixed="right">
                <template slot-scope="scope">
                  <div>
                    <el-button type="text" size="mini" style="padding: 0 5px"
                      :disabled="(scope.row.state == '20' || scope.row.state == '30') && scope.row.mode == '10'"
                      @click="manualAssignmentExaminationRoom(scope.row)">手动分配</el-button>
                    <el-button type="text" size="mini" style="padding: 0 5px"
                      :disabled="scope.row.state == '20' || scope.row.state == '30'"
                      @click="setUpExaminationRoom(scope.row)">自动分配</el-button>
                    <el-button type="text" size="mini" style="padding: 0 5px" :disabled="scope.row.mode == '00'"
                      @click="toSeeExaminationRoom(scope.row)">查看考场</el-button>
                    <el-button type="text" size="mini" style="padding: 0 5px" :disabled="scope.row.state == '10'"
                      @click="toVoid(scope.row)">作废设置</el-button>
                  </div>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog title="设置考场" :visible.sync="dialogsetUpExaminationRoom" width="35%" @close="doNo">
      <el-form :model="setUpExaminationRoomData" style="width: 100%" :rules="rules" ref="setUpExaminationRoomData"
        label-width="100px">
        <el-form-item label="每个考场分" prop="maxNum">
          <el-input style="width: 50%" size="small" v-model="setUpExaminationRoomData.maxNum" @keyup.native="
            setUpExaminationRoomData.maxNum = zF.positiveInteger(
              setUpExaminationRoomData.maxNum
            )
            " @input="setMaxNum" placeholder="请输入考场人数"></el-input>
          个人
        </el-form-item>
        <el-form-item label="准考证号编号" prop="candidateNo">
          <el-input size="small" v-model="setUpExaminationRoomData.candidateNo" placeholder="请输入准考证号编号"></el-input>
        </el-form-item>
        <p style="color:red;">准考证编码规则:准考证号编号 +四位流水号;</p>
        <p style="color:red;">不设准考证编号，则系统自动生成准考证号，格式日期(如：2302020101)+ 四位流水号。</p>
        <br />
        <p>
          学员随机分配到
          <span style="color: red; margin: 0 10px">{{
            setUpExaminationRoomData.examinationRoomNumber
          }}</span>个考场
        </p>
      </el-form>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button @click="doNo">取 消</el-button>
        <el-button class="bgc-bv" type="primary" @click="setUpExaminationRoomYes">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "examinationRoomArrangement_arrange",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      searchForm: {
        activityName: "", //姓名
      },
      // 考场设置 - 弹框
      dialogsetUpExaminationRoom: false,
      // 考场设置 - 弹框数据
      setUpExaminationRoomData: {
        activityId: "", // 考务id
        maxNum: "", // 人数
        candidateNo: "", // 准考证号
        examinationRoomNumber: "", // 考场个数
      },
      // 考场设置 - 弹框数据 - 校验
      rules: {
        maxNum: [
          { required: true, message: "每个考场人数不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created() { },
  computed: {},
  methods: {
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.searchForm.activityName) {
        params.activityName = this.searchForm.activityName;
      }
      this.doFetch(
        {
          url: "/biz/examination_affairs/arrangement/activity/page",
          params,
          pageNum,
        },
        true,
        6
      );
    },
    // 手动分配考场
    manualAssignmentExaminationRoom(row) {
      this.$router.push({
        path: "/web/appraisalInstitution/examinationRoomArrangement/manualAssignmentExaminationRoom",
        query: {
          activityId: row.activityId,
          activityName: row.activityName || '--',
          occupationName: row.occupationName || '--',
          levelCodeName: row.levelCode ? this.$setDictionary("ET_TRAIN_LEVEL", row.levelCode) : '--'
        },
      });
    },

    // 设置考场
    setUpExaminationRoom(row) {
      this.setUpExaminationRoomData.activityId = row.activityId;
      this.dialogsetUpExaminationRoom = true;
    },
    // 设置考场 - 根据每场人数返回考场数量
    setMaxNum() {
      // 判定input的值限制为正整数
      if (this.zF.positiveInteger(this.setUpExaminationRoomData.maxNum)) {
        let cdata = {
          activityId: this.setUpExaminationRoomData.activityId,
          maxNum: this.setUpExaminationRoomData.maxNum,
        };
        if (this.setUpExaminationRoomData.candidateNo) {
          cdata.candidateNo = this.setUpExaminationRoomData.candidateNo;
        }
        this.$post(
          "/biz/examination_affairs/arrangement/room/num",
          cdata,
          3000,
          true,
          6
        )
          .then((res) => {
            this.setUpExaminationRoomData.examinationRoomNumber = res.data;
          })
          .catch(() => {
            return;
          });
      }
    },
    // 设置考场 - 确定
    setUpExaminationRoomYes() {
      this.$refs["setUpExaminationRoomData"].validate((valid) => {
        if (valid) {
          let cdata = {
            activityId: this.setUpExaminationRoomData.activityId,
            maxNum: this.setUpExaminationRoomData.maxNum,
          };
          if (this.setUpExaminationRoomData.candidateNo) {
            cdata.candidateNo = this.setUpExaminationRoomData.candidateNo;
          }
          this.$post(
            "/biz/examination_affairs/arrangement/apportion",
            cdata,
            3000,
            true,
            6
          )
            .then((res) => {
              this.getData(this.pageNum);
              this.$message.success("操作成功！");
              this.dialogsetUpExaminationRoom = false;
            })
            .catch(() => {
              return;
            });
        }
      });
    },
    // 设置考场 - 取消
    doNo() {
      this.dialogsetUpExaminationRoom = false;
      this.$refs["setUpExaminationRoomData"].resetFields();
    },
    // 作废
    toVoid(row) {
      this.$confirm("即将清空所有的考场和考生，如果已经下载了准考证，则需要重新下载，清空后数据将不可恢复，确认作废吗?", "提示", {
        confirmButtonText: "继续作废",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$post(
          "/biz/examination_affairs/arrangement/cancellation",
          { activityId: row.activityId },
          3000,
          true,
          6
        )
          .then((res) => {
            this.$message.success("作废成功！");
            this.getData(this.pageNum);
          })
          .catch(() => {
            return;
          });
      });

    },
    // 跳转 - 查看考场
    toSeeExaminationRoom(row) {
      this.$router.push({
        path: "/web/appraisalInstitution/examinationRoomArrangement/seeExaminationRoom",
        query: {
          activityId: row.activityId,
          mode: row.mode,
        },
      });
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (oldVal.path == "/web/appraisalInstitution/examinationRoomArrangement/manualAssignmentExaminationRoom") {
          this.$nextTick(() => {
            this.getData();
          })

        }
      },
      // 深度观察监听
      deep: true,
    },

  },
};
</script>
<style lang="less" scoped></style>