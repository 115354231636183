import { render, staticRenderFns } from "./arrange.vue?vue&type=template&id=58482175&scoped=true"
import script from "./arrange.vue?vue&type=script&lang=js"
export * from "./arrange.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58482175",
  null
  
)

export default component.exports